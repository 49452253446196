<template>
    <div class="core-box-con">
        <div class="core-con">
            <div class="core-l"><img :src="dataObj.leftMenu"></div>
            <div class="core-r">
                <div class="core-back"><img @click="onBreak" src="../../../assets/break.png"/></div>
                <div class="core-list">
                    <div class="core-slide">
                        <el-carousel height="486px" direction="vertical" :autoplay="false">
                            <el-carousel-item v-for="(item,key) in dataObj.list" :key="key">
                                <img :src="item.src"/>
                            </el-carousel-item>
                        </el-carousel>
                        <div class="core-return"><img :src="dataObj.returnImg"></div>
                    </div>
                    <div class="core-img">
                       <img :src="dataObj.textImg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Content from './content'
export default Content
</script>
<style lang="less">
@import "content";
</style>
