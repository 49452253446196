/**
 * content
 */
import Vue from 'vue';
import { Carousel,CarouselItem } from 'element-ui';
Vue.component(Carousel.name,Carousel);
Vue.component(CarouselItem.name,CarouselItem);
import { coreData } from "@/service/enum";
import {mapState} from "vuex";
const Content = {
    name:'content',
    computed: {
        ...mapState({
            status: state => state.core.status,  //获取状态core列表状态
        }),
    },
    data() {
        return {
            dataObj:{},
        }
    },
    created() {
        if(this.status == 1){
            this.dataObj = coreData[0]
        }
        if(this.status == 2){
            this.dataObj = coreData[1]
        }
        if(this.status == 3){
            this.dataObj = coreData[2]
        }
    },
    methods: {
        onBreak(){
            this.$router.push('/')
            window.location.href = '#core'
        },
    }
}
export default Content
